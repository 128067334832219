const services =
   ["Оборудование выходит из строя, а проблемы с запасными частями не позволяет\n" +
   "быстро возобновить производственный цикл?","Поможем вам сделать срочный ремонт промышленного оборудования до поступления\n" +
   "запасных частей или быстро и качественно произвести замену запасных частей,\n" +
   "восстановление расходных запасных частей.","Ремонт всего спектра механического /гидравлического /пневматического\n" +
    "оборудования.","Выезд оплачивается после возобновлении работоспособности оборудования. Выезд от\n" +
    "10 000 руб."]


const benefits=[
    "Прозрачная система расчетов по договору с закрывающими документами для бухгалтерии;",
    "Все виды оплат;",
    "Гарантия на выполненные работы;",
    "Успешное восстановление работоспособности широкого\n" +
    "спектра промышленного оборудования, даже узкоспециализированного, такого как\n" +
    "гильотины, термованны и.т.д.;",
    "Предоставляем услуги по ежемесячному техническому обслуживанию;"
]
export {services,benefits}