import Card from "../card/card";
import './style.css'

function Benefit({text}){
    return (
      <Card>
          <div className="benefitWrap">
              <img src="/static/ok.png" />
              <h3>{text}</h3>
          </div>
     
      </Card>  
    );
}

export default Benefit