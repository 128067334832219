import './style.css'
import Menu from "../navmenu/navmenu";

function Header() {
    return (
        <header className="header">
            <div className="header-logo">
                <img src="/static/logo.jpg" width="300"/>
            </div>
            
            <div className="header-menu">
                <Menu />
            </div>          
            
            <div className="header-phone-div">
                <a href="tel:+73517771718" className="header-phone">+7 (351) 777-17-18</a>
            </div>
        </header>
    );
}

export default Header