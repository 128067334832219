import './style.css'
import {Link} from "react-scroll";


function Menu() {
    return (
        <nav>
            {items.map((s, i) => (
                <ul key={i}>
                    <li className="navWrapperItem">
                        <Link activeClass="active"
                              to={s.section}
                              spy={true}
                              smooth={true}
                              offset={-70} className="navItem"
                              duration={500}
                        >{s.text}</Link>
                    </li>
                </ul>
            ))}
        </nav>
    )
}

const items = [
    {section: "services", text: "Наши услуги"},
    {section: "benefits", text: "Наши преимущества"},
    {section: "contacts", text: "Контакты"}
]


export default Menu