import './App.css';
import "@fontsource/inter";
import Card from "./components/card/card";
import Header from "./components/header/header";
import {services, benefits} from "./services/DataService"
import Benefit from "./components/benefit/benefit";

function App() {

    return (
        <div className="App">
            <Header/>

            <div className="body">
                <h2 id="services" className="title">Наши услуги</h2>
                <div className='services'>
                    {services.map((s, i) => (
                        <Card key={i}>{s}</Card>
                    ))}
                </div>
                <h2 id="benefits" className="title">Наши преимущества</h2>
                <div className='services'>
                    {benefits.map((s, i) => (
                        <Benefit key={i} text={s}/>
                    ))}
                </div>
                
                <h2 id="contacts" className="title">Наши контакты</h2>
                <div>
                    <div className="aboutWrap">
                        г. Челябинск, ул. Российская, 1А
                        к/тел.: <a href="tel:+73517771718">+7 (351) 777 17 18</a>, <a href="tel:+79822904611">+7 (982) 290 46 11</a> <a href="mailto:servisnic@mail.ru">servisnic@mail.ru</a>
                    </div>
                    <div>

                        <iframe
                            src="https://yandex.ru/map-widget/v1/?um=constructor%3A315428e19717f67e579d23757a08f204a305c1fd9136aa2b124b196ecdc95c99&amp;source=constructor"
                            width="100%" height="324" frameBorder="0"></iframe>
                    </div>
             
                </div>
            </div>

        </div>
    );
}

export default App;
